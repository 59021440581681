

:root {
  font-family: 'Montserrat', 'Poppins', sans-serif;
  color-scheme: light dark;
}
body {
  background-color: #83ac98;
  margin: 0;
  padding: 0;
}